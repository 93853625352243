body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-crop-container {
  overflow: hidden;
  height: 40%;
  /* 画像の高さの40%を表示 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-crop-container img {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  /* 中央の部分を表示 */
}

/* LoadingModal.css */
.progress-bar {
  width: 60%;
  height: 4px;
  background-color: #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.progress-bar-fill {
  height: 100%;
  background-color: teal;
  transition: width 0.1s ease-in-out;
}